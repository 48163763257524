// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require rails-ujs
//= require activestorage
//= require jquery
//= require turbolinks
//= require jquery_ujs
//= require jquery.ui.sortable
//= require jquery.ui.effect-highlight
//= require jquery.jpostal
//= require_tree .
//= require turbolinks

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// $(function() {
  $(document).on('turbolinks:load', () => {
    $('#company_post').jpostal({
      postcode : [
        '#company_post'
      ],
      address: {
        "#company_add1": "%3%4%5%6%7" // # 都道府県が入力される
//        "#user_city"           : "%4%5", // # 市区町村と町域が入力される
//        "#company_add2"         : "%4%5%6%7" // # 大口事務所の番地と名称が入力される
      }
    });
  });

  $(document).on('turbolinks:load', () => {
    $('#pernr_post').jpostal({
      postcode : [
        '#pernr_post'
      ],
      address: {
        "#pernr_add1": "%3%4%5%6%7" ,// # 都道府県が入力される
        "#pernr_sikunm": "%4" // # 


        //        "#user_city"           : "%4%5", // # 市区町村と町域が入力される
    //        "#company_add2"         : "%4%5%6%7" // # 大口事務所の番地と名称が入力される
      }
    });
  });

  $(document).on('turbolinks:load', () => {
    $('#family_post').jpostal({
      postcode : [
        '#family_post'
      ],
      address: {
        "#family_add1": "%3%4%5%6%7" // # 都道府県が入力される
  //        "#user_city"           : "%4%5", // # 市区町村と町域が入力される
  //        "#company_add2"         : "%4%5%6%7" // # 大口事務所の番地と名称が入力される
      }
    });
  });  
// });

  $(document).on('turbolinks:load', () => {
    $('#spouse_post').jpostal({
      postcode : [
        '#spouse_post'
      ],
      address: {
        "#spouse_add1": "%3%4%5%6%7" // # 都道府県が入力される
//        "#user_city"           : "%4%5", // # 市区町村と町域が入力される
//        "#company_add2"         : "%4%5%6%7" // # 大口事務所の番地と名称が入力される
      }
    });
  });  

// # 入力項目フォーマット
// #   %3  都道府県
// #   %4  市区町村
// #   %5  町域
// #   %6  大口事業所の番地 ex)100-6080
// #   %7  大口事業所の名称

    //<!-- モーダルウィンドウ　　-->
    $(document).on('turbolinks:load', (function(){
    // 「.modal-open」をクリック
      $('.modal-open').click(function(){
      // オーバーレイ用の要素を追加
        $('body').append('<div class="modal-overlay"></div>');
        // オーバーレイをフェードイン
        // $('.modal-overlay').fadeIn('slow');
        $('.modal-overlay').fadeIn(1);
        // モーダルコンテンツのIDを取得
        var modal = '#' + $(this).attr('data-target');
        // モーダルコンテンツの表示位置を設定
        modalResize();
        // モーダルコンテンツフェードイン
        // $(modal).fadeIn('slow');
        $(modal).fadeIn(1);
        // 「.modal-overlay」あるいは「.modal-clos」をクリック
        $('.modal-overlay, .modal-clos').off().click(function(){
          // モーダルコンテンツとオーバーレイをフェードアウト
          // $(modal).fadeOut('slow');
          $(modal).fadeOut(1);
          // $('.modal-overlay').fadeOut('slow',function(){
            $('.modal-overlay').fadeOut(1,function(){
            // オーバーレイを削除
            $('.modal-overlay').remove();
          });
        });
        // リサイズしたら表示位置を再取得
        $(window).on('resize', function(){
          modalResize();
        });
        // モーダルコンテンツの表示位置を設定する関数
        function modalResize(){
          // ウィンドウの横幅、高さを取得
          var w = $(window).width();
          var h = $(window).height();
          // モーダルコンテンツの表示位置を取得
          var x = (w - $(modal).outerWidth(true)) / 2;
          var y = (h - $(modal).outerHeight(true)) / 2;
          // モーダルコンテンツの表示位置を設定
          $(modal).css({'left': x + 'px','top': y + 'px'});
        }
      });
    }));
    
  //<!-- モーダルウィンドウ　　-->

  $(document).on('turbolinks:load',(function(){
    $("input").on("keydown", function(e) {
        if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
            return false;
        } else {
            return true;
        }
    });
  }));

  $(document).on('turbolinks:load',(function() {
    $('#check_perid').on('click', function() {
      var check = $(this).prop('checked') 
      $.ajax({
        type: 'POST',
        url: "check_perid",
        data: {"check_perid":check,},
        dataType: 'json'
       })
    })
  }));

  $(document).on('turbolinks:load',(function() {
    $('#check_shozoku').on('click', function() {
      var check = $(this).prop('checked') 
      $.ajax({
        type: 'POST',
        url: "check_shozoku",
        data: {"check_shozoku":check,},
        dataType: 'json'
       })
    })
  }));

$(document).on('turbolinks:load',(function(){
  $('.table-sortable').sortable({
    axis: 'y',
    items: '.item',
    update: function(e, ui) {
      let item = ui.item;
      let item_data = item.data();
      let params = { _method: 'put' };
      let num = item.index() -1;
      params[item_data.model_name] =  { row_order_position: num }
      $.ajax({
        type: 'POST',
        url: item_data.update_url,
        dataType: 'json',
        data: params,
      });
    },
    start(e, ui) {
      let cells, tableWidth, widthForEachCell;
      tableWidth = $(this).width();
      cells = ui.item.children('tr');
      widthForEachCell = tableWidth / cells.length + 'px';
      return cells.css('width', widthForEachCell);
    },
    stop(e, ui) {
      return ui.item.children('tr').effect('highlight');
    },
  });
}));

$(document).on('turbolinks:load',(function() {

  // Check for click events on the navbar burger icon
  $(".navbar-burger").click(function() {

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $(".navbar-burger").toggleClass("is-active");
      $(".navbar-menu").toggleClass("is-active");

  });
}));
